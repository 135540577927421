import api from '@api'
import Constants from '@constants'
import {
  transformApprovalForServer,
  transformApproval,
  transformApprovalStage,
  transformApprover,
  transformApprovalAnalytics,
} from '@data/approval'
import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'

import {
  buildRelationalQualificationStructure,
  buildFlatQualificationStructure,
} from '@data/qualification'

const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

const deletedQuery = (value) =>
  buildRelationalQualificationStructure(
    'removed',
    'equal',
    value,
    'boolean',
    'db'
  )

export function generateApprovalApi(moduleName, parentId) {
  return api.get(`${moduleName}/${parentId}/approval/generate`)
}

export function createApprovalApi(moduleName, parentId, data) {
  return api.post(
    `${moduleName}/${parentId}/approval/generate`,
    transformApprovalForServer(data),
    {
      message: __rootT('created_successfully', {
        resource: __rootTc('approval'),
      }),
    }
  )
}

export function getResourceApprovalsApi(moduleName, parentId, limit, offset) {
  const qualDetails = []
  qualDetails.push(deletedQuery(true))
  qualDetails.push(deletedQuery(false))
  return api
    .post(
      `/${moduleName}/${parentId}/approval/search/byqual`,
      {
        qualDetails:
          [Constants.CHANGE, Constants.RELEASE].indexOf(moduleName) >= 0 &&
          qualDetails.length
            ? buildFlatQualificationStructure(qualDetails)
            : undefined,
      },
      {
        notify: false,
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformApproval),
        total: data.totalCount,
      }
    })
}

export function getResourceApprovalApi(moduleName, parentId, approvalId) {
  return api
    .get(`/${moduleName}/${parentId}/approval/${approvalId}`)
    .then(transformApproval)
}

export function getApprovalStageInformationApi(
  approvalId,
  stageNumber,
  additionalUrlParams = {}
) {
  return api
    .get(`/approval/${approvalId}/stage/${stageNumber}`, {
      params: {
        ...additionalUrlParams,
      },
    })
    .then(transformApprovalStage)
}

export function getApproversApi(id, limit, offset, additionalUrlParams = {}) {
  return api
    .get(`/approvalstage/${id}/approver`, {
      params: {
        offset: offset || 0,
        size: limit === undefined ? 1000 : limit,
        ...additionalUrlParams,
      },
    })
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformApprover),
        total: data.totalCount,
      }
    })
}

export function updateApproverApi(data, isPublic) {
  return api
    .patch(
      `${isPublic ? '/cportal' : ''}/approver/${data.id}`,
      {
        approverStatus: data.status,
        notes: data.notes,
        noteAttachments: data.noteAttachments,
      },
      {
        message: __rootT('status_changed_successfully', {
          status: __rootTc(data.status),
          resource: `${__rootTc('approval')} ${__rootTc('request')}`,
        }),
      }
    )
    .then(transformApprover)
}

export function getApprovalAnalyticsApi(moduleName, parentId) {
  return api
    .get(`/${moduleName}/${parentId}/approval/analytics`)
    .then(transformApprovalAnalytics)
}

export function remindApproverApi(id) {
  return api.get(`approver/${id}/remind`, {
    notification: {
      message: __rootT('success'),
      description: __rootT('sent_successfully', {
        resource: __rootT('reminder'),
      }),
    },
  })
}

export function ignoreApproverApi(id) {
  return api.get(`approver/${id}/ignore`, {
    message: __rootT('status_changed_successfully', {
      status: __rootTc('ignored'),
      resource: __rootTc('approval'),
    }),
  })
}

export function reRequestReferredBackApprovalApi(stageNumber) {
  return api.get(`/approvalstage/reactivate/${stageNumber}`, {
    message: __rootT('status_changed_successfully', {
      status: __rootTc('re_requested'),
      resource: __rootTc('approval'),
    }),
  })
}

export function deleteApproverApi(id) {
  return api.delete(`approver/${id}`, {
    message: __rootT('status_changed_successfully', {
      status: __rootTc('delete'),
      resource: __rootTc('approval'),
    }),
  })
}

export function getMakercheckerUpdateDataApi(id) {
  return api.get(`/maker_checker/${id}`)
}
